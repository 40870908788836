import ModeEnum from './ModeEnum';
export default class Vehicle {
  constructor() {
    this.authMode = null;
  }

  _minimalMode() {
    return {
      mode: this.authMode,
      drivingLicenseMainUrl: true, // 行驶证主页url
      transportLicenseMainUrl: true, // 道路运输证（选填）
      carLicensePlate: true, // 车牌号
      truckTypeName: true, // 车辆类型名称
      trailerPlateNumber: true, // 挂车牌照号
      loadWeight: true, // 核定载质量
      totalWeight: true, // 总质量
      trailerType: true,
      trailerLoadWeight: true,
      loadLength: true,
      axles: true
    };
  }

  _mediumMode() {
    return {
      ...this._minimalMode(),
      plateColor: true, // 车牌颜色
      energyType: true, // 能源类型
      roadTransportNumber: true // 道路运输证号
    };
  }

  _completeMode() {
    return {
      ...this._minimalMode(),
      ...this._mediumMode(),
      owner: true, // 车辆所有人
      useNature: true, // 使用性质
      identifyCode: true, // 车辆识别代号
      registerDate: true, // 行驶证注册日期
      issueDate: true, // 行驶证发证日期
      issueOrganization: true, // 行驶证发证机关
      businessName: true, // 业户名称
      roadTransportPermitNumber: true, // 道路运输许可证号
      fileNumber: true, // 档案编号
      outlineDimensionHeight: true, // 外廓尺寸高
      outlineDimensionLength: true, // 外廓尺寸长
      outlineDimensionWidth: true, // 外廓尺寸宽
      curbWeight: true,
      inspectionEndDate: true,
      validEndDate: true
    };
  }

  build(authMode) {
    this.autMode = authMode;
    if (authMode === ModeEnum.SIMPLE) return this._minimalMode();
    if (authMode === ModeEnum.MEDIUM) return this._mediumMode();
    return this._completeMode();
  }
}
