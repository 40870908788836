<template>
  <Page class="mine-car pb-72 pt-16">
    <Navbar title="车辆认证" @clickLeft="handleClickBack" :customClickLeft="true"> </Navbar>
    <div class="bg-white h-full overflow-scroll">
      <!-- 进度条 -->
      <TosbStep class="step pa-16 pb-0" :steps="steps" :current="showStep" />

      <!-- 步骤一 -->
      <div class="pt-34" v-if="init">
        <component :is="currentStepComponent"></component>
      </div>
    </div>
  </Page>
</template>
<script>
import { defineComponent, computed, onBeforeMount, nextTick, ref } from '@vue/composition-api';
import { Button, Dialog } from 'vant';
import TosbStep from '@/components/Step/index.vue';

import { steps } from '../../constants/index';
import { useRouter } from '@/utils/compsitionHack';
import { useRouterQuery } from '@hook/useRouterQuery';
import { useUserStore } from '@/store/user';
import { PageEnum } from '@/enums/pageEnum';

import { useTruckVerifiedStore, emptyForm } from '@/store/truckVerified';
import { getPlatformAppAuditModeApi } from '@/api/common';

import AuthModeFactory from '@/services/AuthenticationMode/AuthModeFactory';
import Vehicle from '@/services/AuthenticationMode/Vehicle';
import ModeEnum from '@/services/AuthenticationMode/ModeEnum';
const authModeFactory = new AuthModeFactory(new Vehicle());
export default defineComponent({
  name: 'AddCar',
  components: {
    Button,
    TosbStep,
    Step1: () => import('../components/step1'),
    Step2: () => import('../components/step2'),
    Step3: () => import('../components/step3'),
    Step4: () => import('../components/step4'),
    Step5: () => import('../components/step5.vue'),
    StepTrailer: () => import('../components/step-trailer.vue')
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    const routerQuery = useRouterQuery();

    const userVerifiedStore = useTruckVerifiedStore();
    const isDriver = computed(() => userStore.isDriver);
    const step = computed(() => userVerifiedStore.step || 0);
    const showStep = computed(() => {
      const currStep = step.value;
      if (currStep === 'trailer') return 1;
      return currStep;
    });
    const init = ref(false);
    // 判断当前显示步骤几的组件
    const currentStepComponent = computed(() => {
      const currStep = userVerifiedStore.step;
      console.log(currStep);
      if (currStep === 'trailer') return 'StepTrailer';
      return `Step${currStep + 1}`;
    });

    onBeforeMount(async () => {
      userVerifiedStore.resetStep();
      if (routerQuery.truckId) {
        // 编辑状态
        await userVerifiedStore.queryVerifiedInfo(routerQuery.truckId);
      } else {
        userVerifiedStore.updateAuthModeFields(authModeFactory.build(ModeEnum.INTEGRITY));
        userVerifiedStore.updateInfo(emptyForm());
        const res = await getPlatformAppAuditModeApi();

        // 需要请求一个借口判断是什么认证类型
        userVerifiedStore.updateAuthModeFields(authModeFactory.build(res.data));
      }
      await userVerifiedStore.getTuckInitConfig();
      console.log(currentStepComponent.value);
      init.value = true;
    });

    const sureBack = async () => {
      // 如果是在客户端环境，调用客户端返回方法
      userVerifiedStore.clearInfo();
      // 不同的身份跳转不同地方
      nextTick(() => {
        router.push(isDriver.value ? PageEnum.BASE_CAR_LIST : PageEnum.CARRIER_FLEET);
      });
    };

    const handleClickBack = () => {
      const lastStep = userVerifiedStore.lastStep;

      if (step.value === 0 || step.value === 4 || !lastStep.length) {
        if (step.value === 0) {
          Dialog.confirm({
            message: '信息暂未提交认证，退出后信息不会保存，是否继续操作!'
          })
            .then(() => {
              sureBack();
            })
            .catch(() => {});
        } else {
          sureBack();
        }
      } else {
        userVerifiedStore.backStep();
      }
    };

    return {
      currentStepComponent,
      steps,
      step,
      handleClickBack,
      init,
      showStep
    };
  }
});
</script>
<style lang="less">
.mine-car {
  min-height: 100vh;
  &.keyboard {
    padding-bottom: 0.3rem !important;
  }
}
.van-field__error-message {
  text-align: right;
}
.carnumber-box {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  font-size: 0.2rem;
  line-height: 0.5rem;
  border-radius: 0.05rem;
  text-align: center;
  border: 1px solid #eee;
  + .carnumber-box {
    margin-left: 0.02rem;
  }
}
.titleError {
  color: #ee0a24;
  .van-field__label {
    color: #ee0a24;
  }
}
</style>
